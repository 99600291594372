/**
 * DynamicForm
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useLayoutEffect, useState } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { buildYup } from 'schema-to-yup';

import { useHotkeys } from 'react-hotkeys-hook';
import { Form, Formik } from 'formik';

import { ObjectUtil } from '@/utils';
import { useLocation } from '@/router/index';
import { getTranslated } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/dist/unstable';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';

import { DynamicFormFieldPart } from './parts/DynamicFormFieldPart';


export interface DynamicFormProps {
    payload?: Record<any, any>;
    savedValues?: Record<any, any>;
    onSubmit?: (values: any) => void;
    loading?: boolean;
    validation?: Record<any, any>;

    fields: Array<{
        type: 'input'
            | 'textarea'
            | 'select'
            | 'checkbox'
            | 'banner'
            | 'copy-input'
            | 'custom-html';
        name: string;
        top?: string;
        placeholder?: string;
        defaultValue?: string;
        title?: string;
        hidden?: boolean;
        description?: string;
        rows?: number;
        maxLength?: number;
        hideIf?: Record<string, boolean[]>;
        options?: { label: string, value: string }[];
        templates?: string[];
        props?: Record<string, any>;
    }>;

    button: {
        text: string;
        savedText?: string;
    };
}


const DynamicForm = observer((props: DynamicFormProps) => {

    const {
        fields,
        button,
        loading,
        payload,
        savedValues,
        onSubmit = () => {},
    } = props;

    const { route: { params } } = useLocation();

    const validation = _.cloneDeep(props.validation);

    const yupSchema = validation
        ? buildYup(validation?.schema, getTranslated(validation?.config))
        : undefined;

    const [ forceShowHidden, setForceShowHidden ] = useState(false);
    const [ initialValues, setInitialValues ] = useState<Record<string, string>>({});

    useLayoutEffect(() => {
        const newInitialValues: Record<string, string> = {};

        for (const field of fields || []) {
            const { name, defaultValue } = field;

            if (name.startsWith('_')) {
                continue;
            }

            newInitialValues[name] = _.isNil(savedValues?.[name])
                ? defaultValue
                : savedValues?.[name];
        }

        setInitialValues(newInitialValues);
    }, [ fields, savedValues ]);

    useHotkeys('e+x+d+0', () => {
        setForceShowHidden(!forceShowHidden);
    }, [ forceShowHidden ]);

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                enableReinitialize
                onSubmit={onSubmit}
                key={ConfigStore.language}
                validationSchema={yupSchema}
                initialValues={initialValues}>
            {(formik) => {
                const {
                    values,
                    isValid,
                    errors,
                    handleSubmit,
                } = formik;

                return (
                    <Form onSubmit={handleSubmit}>
                        {fields?.map((field, index) => (
                            <DynamicFormFieldPart key={index}
                                                  index={index}
                                                  field={field}
                                                  config={formik}
                                                  payload={payload}
                                                  forceShowHidden={forceShowHidden}/>
                        ))}

                        <StickyButton className="px-0 pb-3 mt-6 z-[2]">
                            <TextTooltip placement="left" shown={!isValid && !!params.modal} hidden={isValid} text={(
                                <CheckListTooltip list={_.values(errors).map((text) => ({
                                    mode: 'error',
                                    text: `${text}`,
                                }))}/>
                            )}>
                                <Button stretched
                                        size="l"
                                        type="submit"
                                        mode="commerce"
                                        loading={loading}
                                        data-test="dynamic-form.submit"
                                        disabled={(
                                            !isValid
                                            || loading
                                            || ObjectUtil.isEqual(initialValues, values)
                                        )}>
                                    {ObjectUtil.isEqual(initialValues, values) && button.savedText || button.text}
                                </Button>
                            </TextTooltip>
                        </StickyButton>
                    </Form>
                );
            }}
        </Formik>
    );
});


export { DynamicForm };
