/**
 * UploadService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import axios, { Canceler } from 'axios';

import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { Notify } from '@/cutils';
import { FileUtil } from '@/utils';
import { RestApi } from '@/api/restapi';

import { StorageImageSize } from '@/codegen/graphql';
import { STORAGE_UPLOAD_MAX_SIZE } from '@/root/src/env';

import { SaveStoreKeys, SavingStore } from '@/store/core/saving';


class UploadService {

    /**
     * Фиксация загрузки для времени загрузки в bucket
     * @param {number} progress
     */
    static adjustedUploadProgress(progress: number) {
        const result = progress > 60 && progress < 99
            ? Math.max(60, progress * 0.85)
            : progress;

        return Math.round(result);
    }

    /**
     * Загрузка файлов на сервер
     * @param {string} id
     * @param {FormData} formData
     * @param {} options
     */
    static async uploadFiles(
        id: string,
        formData: FormData,
        options: {
            cancelRef?: MutableRefObject<Canceler | null>;
            setUploadProgress?: Dispatch<SetStateAction<any>>;
            imageSizes?: StorageImageSize[];
        } = {},
    ) {
        const {
            cancelRef,
            imageSizes,
            setUploadProgress,
        } = options;

        const files = formData.getAll('files') as File[];

        const requiredFormData = [ 'space', 'files', 'meta[0][name]' ];

        if (requiredFormData.some((item) => !formData.get(item))) {
            throw new Error('Not valid arguments, please check');
        }

        const getParams = () => {
            const params = formData;

            if (imageSizes && !_.isEmpty(imageSizes)) {
                imageSizes.forEach((item) => params.append('imageSizes[]', item));
            }

            return params;
        };

        /** File limitations and filters */
        const appendedFiles = _.map(files, (file) => {
            if (file.size >= STORAGE_UPLOAD_MAX_SIZE) {
                Notify.toast.error(
                    <span className="fs-14">
                        Файл <b>{file.name}</b> ({FileUtil.getFileSize(file.size)}) более
                        {' '}
                        <b>{FileUtil.getFileSize(STORAGE_UPLOAD_MAX_SIZE)}</b>
                    </span>,
                );

                return false;
            }

            return file;
        });

        /** Break file upload if no one file appended */
        if (!appendedFiles.some((e) => !!e)) {
            return { success: false, exception: 'no-files' };
        }

        return RestApi.post('storage/upload/files', getParams(), {}, {
            onUploadProgress: ({ loaded, total }) => {
                SavingStore.setSavingRecord(SaveStoreKeys.Uploading, id, true);

                const progress = (100 * loaded) / (total || 1);

                setUploadProgress?.(this.adjustedUploadProgress(progress));
            },
            cancelToken: new axios.CancelToken((cancel) => {
                if (cancelRef) {
                    cancelRef.current = cancel;
                }
            }),
        }).catch(error => {
            console.log(error);
            axios.isCancel(error) && console.log(error);

            SavingStore.setSavingRecord(SaveStoreKeys.Uploading, id, false);
        });
    }

}


export { UploadService };
