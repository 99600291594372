/**
 * CartFloatButton
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ShopStore } from '@/store/user/shop';
import { ConfigStore, observer } from '@/store/core/config';

import { If, Link, Responsive, Router } from '@/cutils';
import { usePlatformDigitalIsOnSale } from '@/hooks/core';

import { Icon28ShoppingCartOutline } from '@vkontakte/icons';

import { FloatingButton } from '@/components/Atoms/FloatingButton';


const CartFloatButton = observer(() => {

    const { digitalIsOnSale } = usePlatformDigitalIsOnSale();

    return (
        <If is={digitalIsOnSale}>
            <Responsive.Mobile>
                <Router pages={[
                    '/',
                    '/menu',
                    '/catalog',
                    '/catalog/:page([0-9]+)',
                    '/education',
                    '/education/:page([0-9]+)',
                ]}>
                    <If is={!!ShopStore.cartLength}>
                        <Link disabled pushPage={{ id: '/cart' }}>
                            <FloatingButton className="next-release"
                                            badgeColor="var(--white)"
                                            forDesktop={ConfigStore.isDesktop}
                                            icon={<Icon28ShoppingCartOutline/>}
                                            badge={<b>{ShopStore.cartLength}</b>}
                                            isLeftSided={ConfigStore.isMobileOnly}
                                            badgeBg="linear-gradient(70deg, var(--exode_red), var(--exode_orange))"/>
                        </Link>
                    </If>
                </Router>
            </Responsive.Mobile>
        </If>
    );
});


export { CartFloatButton };
