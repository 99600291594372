/**
 * PriceItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { renderToString } from 'react-dom/server';

import { observer } from '@/pages/Core';
import { ShopStore } from '@/store/user/shop';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { CourseFindManyItem } from '@/types/course';

import { Text } from '@exode.ru/vkui';
import { Icon28GiftOutline, Icon28ShoppingCartOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { AmountText } from '@/components/Atoms/AmountText';


interface Props {
    product: CourseFindManyItem['product'];
}


const PriceItem = observer((props: Props) => {

    const { product } = props;

    const { t } = useI18n('components.Course.CourseCard');

    const inCart = ShopStore.checkInCart(
        product.id,
        product.minimalPrice?.amount || 0,
        'product',
    );

    return (
        <>
            <If is={!product.isFree}>
                <If is={!inCart}>
                    <Text className="font-semibold fs-14">
                        <InnerHtml content={t('fromPrice', {
                            amount: renderToString(
                                <AmountText currency={product.currency}
                                            amount={product.minimalPrice?.amount || 0}
                                            exchange={product.minimalPrice?.amountExchangeRates?.amount}/>,
                            ),
                        })}/>
                    </Text>
                </If>

                <If is={inCart}>
                    <Link disabled stopPropagation pushPage={{ id: '/cart' }}>
                        <div className="flex items-center gap-1">
                            <Icon28ShoppingCartOutline width={16} height={16}/>

                            <Text className="font-semibold fs-14">
                                {t('inCart')}
                            </Text>
                        </div>
                    </Link>
                </If>
            </If>

            <If is={product.isFree}>
                <div className="flex items-center gap-1 text-marker !pr-0.5 mr-0">
                    <Icon28GiftOutline width={16} height={16}/>

                    <Text className="font-semibold fs-14">
                        {t('free')}
                    </Text>
                </div>
            </If>
        </>
    );
});


export { PriceItem };
